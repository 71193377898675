import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared/shared.module';
import { CloudBackupComponent } from './cloud-backup/cloud-backup.component';
import { MarketplaceConfigComponent } from './marketplace-config/marketplace-config.component';
import { SettingsComponent } from './settings/settings.component';
import { WhiteLabelComponent } from './whitelabel.component';
import { WhiteLabelRoutes } from './whitelabel.routes';

import { NgbModalModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'app/shared/components/confirmation-modal/confirmation-modal.component';
import { CloudBackupPreviewDialogComponent } from './cloud-backup/cloud-backup-preview-dialog/cloud-backup-preview-dialog.component';
import {
    MarketplaceConfigPreviewDialogComponent
} from './marketplace-config/marketplace-config-preview-dialog/marketplace-config-preview-dialog.component';
import { CustomUrlComponent } from './shared/custom-url/custom-url.component';
import { CustomerSupportComponent } from './shared/customer-support/customer-support.component';
import { PublishComponent } from './shared/publish/publish.component';

@NgModule({
    declarations: [
        WhiteLabelComponent,
        CloudBackupComponent,
        SettingsComponent,
        MarketplaceConfigComponent,
        CustomerSupportComponent,
        CustomUrlComponent,
        PublishComponent,
        CloudBackupPreviewDialogComponent,
        MarketplaceConfigPreviewDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        NgbModalModule,
        NgbPopoverModule,
        NgbTooltipModule,
        ReactiveFormsModule,
        RouterModule.forChild(WhiteLabelRoutes)
    ],
    exports: [
        WhiteLabelComponent,
        CloudBackupComponent,
        SettingsComponent,
        MarketplaceConfigComponent,
        CustomerSupportComponent,
        CustomUrlComponent,
        PublishComponent,
        RouterModule
    ],
    entryComponents: [
        // Dynamic components like modals go here.
        CloudBackupPreviewDialogComponent,
        MarketplaceConfigPreviewDialogComponent,
        ConfirmationModalComponent
    ]
})
export class WhiteLabelModule { }
